<template>
  <div :class="{container:true,notScroll:isScroll}">
    <MoTitle :showLeft="false" title="基础人事系统" @moTitltFn="navPanel"></MoTitle>
    <div class="moPayContent">
      <!--头部 -->
        <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">基础人事系统</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
        </div>

        <div class="contentBgColor center">
          <h2 class="h2Desc">基础人事系统</h2>
          <span class="spanDesc"></span>
          <div class="designIdea">
           以用户组织岗位体系、人员信息库为基础，实现不同类别人员入转调离全生命周期基础人事业务管理，推动人事管理理念更新、模式变革、流程优化，构建用户智慧HR管理体系。
          </div>
          <el-button @click="$router.push('/moShow')" class="knowMoreBtn">了解更多</el-button>
          <img src="@/assets/product/basePeople/personalIndex.png" alt="" style="width:100%;margin:20px 0">
        </div>
        <!-- 系统架构 -->
        <div class="contentBgColor center">
          <h2 class="h2Desc">系统功能图</h2>
          <span class="spanDesc"></span>
          <img src="@/assets/product/basePeople/frame.png" alt="" style="width:100%;margin:30px 0">
        </div>

        <div class="contentBgColor center">
          <h2 class="h2Desc">系统价值优势</h2>
          <span class="spanDesc"></span>
          <div class="moValueContent">
            <div v-for="(item,index) in valueList" :key="index" class="moValueItem">
              <div class="moValueTitle">{{item.title}}</div>
              <div class="moValueLine"></div>
              <div class="moValueText">{{item.content}}</div>
            </div>
          </div>
        </div>

      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'

export default {
  name: 'moBasePeople',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      valueList: [
        { title: '全域员工信息库', content: '通过统一规范数据标准、分级数据管理、规范数据过程、保证数据安全、共享数据，搭建符合单位统一数据治理框架的员工信息库。' },
        { title: '全生命周期业务流', content: '按照单位不同人员分类，实现其从入职到离职退休的全生命周期的发展管理，展示员工成长轨迹，为人才培养提供依据。' },
        { title: '智能盘点与决策分析', content: '发挥数据价值，构建决策支持模型，让单位领导实时动态掌握全单位师资力量、学科建设、发展趋势。' }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/product/productBasePeople')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #222;
    box-sizing: border-box;
}
img {
    object-fit: cover;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.clickBtn{
  box-shadow: 0 .375rem 1.25rem -0.375rem rgba(131, 7, 7, 0.4);
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.freeMinBtn{
  width: 6rem;
  height: 2rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 1.875rem;
  border: 0;
  line-height: 2rem;
  font-size: .875rem;
  color: #fff;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.pdesc {
  font-size: 1rem;
  color: #222;
  line-height: 1.5rem;
  margin-top: .625rem;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.moPayContent{
  padding-top: 2.75rem;
}
.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/product/basePeople/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}

.designIdea{
  margin: 20px 0;
  font-size: .9375rem;
line-height: 1.5rem;

}
.knowMoreBtn{
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 30px;
  border: 1px solid rgba(211,218,228,0.5);
  color: #444444;
  font-size: 14px;
}

.moValueContent{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItem{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moValueTitle{
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  line-height: 24px;
}
.moValueLine{
  width: 20px;
  height: 3px;
  background: #D70C0C;
  margin: 8px 0;
}
.moValueText{
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
}
</style>
